<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel expand>
        <v-expansion-panel-header>
          <div class="v-card__title">{{ translate("Client Info") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <br />
          <v-form v-model="first_form" ref="first_form" lazy-validation class="v-form multi-col-validation">
            <v-row>
              <v-col><v-btn @click="searchClientDialog = true" color="primary">
                  {{ translate("Search") }}</v-btn>
                &nbsp;&nbsp;
                <v-btn @click="newClientDialog = true" color="success">
                  {{ translate("New Client") }}
                </v-btn></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field required v-model="client_id" :label="translate('Client ID')"
                  @keydown.enter="searchForClient" filled clear-icon="shrink" :rules="emptyRule" outlined type="name"
                  dense></v-text-field>
              </v-col>
              <v-col>
                <v-text-field required v-model="client_name" :label="translate('Client Name')" clear-icon="shrink"
                  :rules="emptyRule" outlined type="name" dense></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="phone_number" :label="translate('Phone Number')" clear-icon="shrink"
                  :rules="emptyRule" outlined type="number" dense></v-text-field>
              </v-col>

              <v-col>
                <v-text-field v-model="address" :label="translate('Address')" clear-icon="shrink" outlined type="email"
                  dense></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="zip_code" :label="translate('Zip code')" clear-icon="shrink" outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="idNumber" :label="translate('ID Number')" clear-icon="shrink" outlined
                  dense></v-text-field></v-col>

              <v-col>
                <v-select style="min-width: 150px" filled outlined :label="translate('ID Type')" dense v-model="idType"
                  :items="[
                    {
                      text: 'NUIS',
                      value: 0,
                    },
                    {
                      text: 'ID Card',
                      value: 1,
                    },
                    {
                      text: 'Passport Number',
                      value: 2,
                    },
                    {
                      text: 'Number of VAT',
                      value: 3,
                    },
                    {
                      text: 'Tax Number',
                      value: 4,
                    },
                    {
                      text: 'Social Security Number',
                      value: 5,
                    },
                  ]"></v-select>
              </v-col>
              <v-col>
                <v-autocomplete v-model="state_id" outlined dense label="State" :items="states" item-text="name"
                  item-value="id"></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete v-if="state_id == 1" v-model="city_id" outlined dense label="City" :items="cities"
                  item-text="city_name" item-value="city_id"></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-if="state_id == 1 && city_id != null && cities.find(stock => stock.city_id == city_id).city_areas != null"
                  v-model="area_id" outlined dense label="City Area"
                  :items="cities.find(stock => stock.city_id == city_id).city_areas" item-text="area_name"
                  item-value="area_id"></v-autocomplete>
              </v-col>
            </v-row>
            <br />
            <l-map style="height: 500px; width: 100%" :zoom="zoom" :center="center" @click="mapclick">
              <l-polyline v-if="get_magazine != null" :lat-lngs="[
                [
                  { lat: get_magazine.lat, lng: get_magazine.lng },
                  { lat: markerLatLng[0], lng: markerLatLng[1] },
                ],
              ]" @click="alert(item)" />
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-marker :lat-lng="markerLatLng">
                <l-tooltip :options="{ permanent: true }">
                  {{ client_name }}
                </l-tooltip>
              </l-marker>
              <l-marker v-if="get_magazine != null" :lat-lng="[get_magazine.lat, get_magazine.lng]">
                <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" icon-url="apartment.png" />

                <l-tooltip :options="{ permanent: true }">
                  {{ get_magazine.name }}
                </l-tooltip>
              </l-marker>
            </l-map>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="v-card__title">{{ translate("Order Info") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="v-card__text">
            <v-form v-model="second_form" ref="second_form" lazy-validation class="v-form multi-col-validation">
              <div class="row">
                <div class="col-md-6 col-12">
                  <label class="text-center text-uppercase text--primary bold">{{
                    translate("Deadline")
                  }}</label>

                  <div
                    class="v-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                    <div class="v-input__control">
                      <div class="v-input__slot">
                        <fieldset aria-hidden="true">
                          <legend style="width: 0px">
                            <span class="notranslate">​</span>
                          </legend>
                        </fieldset>

                        <v-datetime-picker dense class="outlined shrink" v-model="deadline" @input="menu2">
                        </v-datetime-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <label class="text-center text-uppercase text--primary bold">{{
                    translate("Magazine")
                  }}</label>

                  <v-select :rules="[(v) => !!v || 'Item is required!']" outlined filled dense v-model="get_magazine"
                    item-text="name" @input="(name) => magazine_selected(name)"
                    :items="stocks.map((a) => a.magazine_name)"></v-select>
                </div>

                <div class="col-md-6 col-12">
                  <!--<v-row>
                    <v-col>
                      <v-select
                        filled
                        :rules="[v => !!v || 'Item is required!']"
                        outlined
                        :label="translate('Sales Post')"
                        dense
                        v-model="sales_post"
                        :items="getDrivers"
                      ></v-select>
                    </v-col>
                    <v-col v-if="delivery_fee_visible">
                      <v-text-field
                        v-model="delivery_fee"
                        :label="translate('Delivery Fee')"
                        clear-icon="shrink"
                        :suffix="currency"
                        outlined
                        type="number"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <v-row dense>
                    <v-col dense>
                      <v-btn @click="openImagePrompt" small color="success" fab>
                        <v-icon> mdi-image-plus </v-icon>
                        <input ref="uploader" class="d-none" type="file" accept="" @change="uploadImage" />
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-select :rules="[(v) => !!v || 'Item is required!']" outlined :label="translate('Origin')" dense
                        filled item-text="name" item-value="id" v-model="origin" :items="origins"></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <div v-for="image in computedImages" :key="image.id">
                      <div style="
                          overflow: visible;
                          position: relative;
                          min-width: 110px;
                          max-width: 110px;
                          min-height: 110px;
                          max-height: 110px;
                          margin: 5px;
                          padding: 5px;
                          border-style: dashed;
                        ">
                        <div>
                          <a :href="image.url" target="_blank"><v-img v-if="isFileImage(image)"
                              style="height: 95px; width: 100%" :src="image.url"></v-img>
                            <span v-else style="
                                -webkit-line-clamp: 7;
                                display: -webkit-box;
                                line-height: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                line-break: anywhere;
                              ">
                              {{ image.name }}</span>
                          </a>
                        </div>

                        <v-btn @click="removeOrderImage(image)" fab dark class="customBtn" style="
                            position: absolute;
                            top: -10px;
                            right: -10px;
                            height: 25px;
                            width: 25px;
                          " color="error">
                          <v-icon x-small dark> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-row>
                </div>
                <div class="col-md-6 col-12">
                  <v-row>
                    <v-col>
                      <v-select :rules="[(v) => v != null || 'Item is required!']" outlined
                        :label="translate('Payment method')" dense filled item-value="id" item-text="text"
                        v-model="payment_method" :items="payment_methods"></v-select>
                    </v-col>

                    <v-col>
                      <!-- <v-select
                        :rules="[v => selectedProductions.length != 0 || 'Item is required!']"
                        outlined
                        :label="translate('Production')"
                        dense
                        chips
                        filled
                        item-text="text"
                        v-model="selectedProductions"
                        :items="
                          getProductions.map(item => {
                            return { text: item.name, value: item }
                          })
                        "
                      ></v-select
                    >
                    -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select v-if="state_id != 1" outlined :label="translate('Currency')" dense filled item-value="id"
                        item-text="text" v-model="currency_id" :items="[
                          { id: 1, text: 'Lek' },
                          { id: 2, text: 'Euro' }
                        ]"></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="payment_amount" v-if="state_id != 1 && currency_id == 2"
                        :label="'Total Payment (Euro)'" clear-icon="shrink" outlined type="number" dense></v-text-field>
                    </v-col>

                    <v-col>

                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
          </div>

          <v-card class="mx-auto mt-10" outlined v-if="selected_magazine != null">
            <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
              <div data-v-6fcca708="" class="me-2 mb-2">
                <div style="display: flex">
                  <span>
                    <div data-v-6fcca708="" class="v-card__title">
                      {{ translate("Stocks") }}
                    </div>
                  </span>
                  <span><v-autocomplete @change="productSkuChanged" item-value="product_id" outlined item-text="skuName"
                      :filter="productSkuFilter" :items="computedProducts.filter((item) => item.product_type == 'single')
                        " :label="translate('Search by SKU')"></v-autocomplete>
                  </span>
                </div>
              </div>
              <button data-v-6fcca708="" v-on:click="
                selected_stocks.push({
                  id: Math.random() * 10000,
                  product_id: stocks[0].product_id,
                  image_url: '',

                  amount: 0,
                  quantity_price: 0,
                  tax:
                    selected_stocks.length == 0
                      ? 0
                      : selected_stocks.slice(-1).pop().tax,
                  discount: 0,
                  type: '',
                })
                " type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">
                <span class="v-btn__content">{{ translate("add") }}</span>
              </button>
            </div>
            <v-form v-model="third_form" ref="third_form" lazy-validation>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase">Image</th>

                      <th class="text-center text-uppercase">ID</th>

                      <th class="text-center text-uppercase">
                        {{ translate("Product") }}
                      </th>

                      <th class="text-center text-uppercase">
                        {{ translate("Quantity Price") }}
                      </th>
                      <th class="text-center text-uppercase">
                        {{ translate("Amount") }}
                      </th>
                      <th class="text-center text-uppercase">
                        {{ translate("Discount") }} %
                      </th>
                      <!--<th class="text-center text-uppercase">{{ translate('Tax') }} %</th>-->
                      <th class="text-center text-uppercase">{{ translate("Total") }}</th>
                      <th class="text-center text-uppercase">{{ translate("") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="stock in selected_stocks" :key="stock.id">
                      <td class="text-center">
                        <div style="
                            overflow: visible;
                            position: relative;
                            min-width: 110px;
                            max-width: 110px;
                            min-height: 110px;
                            max-height: 110px;
                            margin: 5px;
                            padding: 5px;
                            border-style: dashed;
                          ">
                          <a :href="stock.image_url" target="_blank"><v-img style="height: 95px; width: 100%"
                              :src="stock.image_url"></v-img></a>
                        </div>
                      </td>
                      <td class="text-center">
                        <v-text-field @input="product_id_changed(stock)" v-model="stock['product_id']" :label="'ID'"
                          clear-icon="shrink" outlined type="number" dense></v-text-field>
                      </td>
                      <td class="text-center" style="padding: 10px">
                        <v-select :rules="[(v) => !!v || 'Item is required']" dense item-text="product_name"
                          v-model="stock['product_name']" class="shrink" outlined
                          @input="(id) => product_selected(stock, id)" :items="computedProducts"></v-select>
                      </td>

                      <td class="text-center" style="padding: 10px">
                        <v-text-field v-model="stock['quantity_price']" @input="computedStockKey += 1" :label="`${translate('Price of one ')}` + stock.measurement_name
                          " clear-icon="shrink" :suffix="currency" outlined type="number" dense></v-text-field>
                      </td>
                      <td class="text-center col-2" style="padding: 10px">
                        <v-text-field @input="computedStockKey += 1" :label="`${translate('Amount')}` +
                          `(${translate('available')}: ` +
                          computedStocks.find((x) => x.product_id === stock.product_id)
                            .amount +
                          ')'
                          " :key="key" v-model="stock['amount']" :suffix="stock.measurement_name" outlined dense
                          :rules="[
                            (v) => !!v || 'Item is required!',
                            (v) =>
                              computedStocks.find(
                                (x) => x.product_id === stock.product_id
                              ).amount >= 0 || translate('Not enough in Stock'),
                          ]" :min="1" :max="computedStocks.find((x) => x.product_id === stock.product_id)
  .amount
  " type="number"></v-text-field>
                      </td>
                      <td class="text-center" style="padding: 10px">
                        <v-text-field :label="translate('Discount')" v-model="stock['discount']" suffix="%" outlined dense
                          type="number"></v-text-field>
                      </td>
                      <!--<td class="text-center" style="padding: 10px">
                        <v-text-field
                          :label="translate('Tax')"
                          v-model="stock['tax']"
                          suffix="%"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </td>-->
                      <td>
                        <label v-if="stock['amount'] == 0 || stock['quantity_price'] == 0">0 {{ currency }}</label>
                        <label v-else>{{ calculateTotal(stock) }} {{ currency }}</label>
                      </td>

                      <td class="text-center">
                        <div v-on:click="
                          selected_stocks = selected_stocks.filter(
                            (item) => item !== stock
                          )
                          " class="v-avatar elevation-1 rounded error"
                          style="height: 30px; min-width: 30px; width: 30px">
                          <span aria-hidden="true" class="v-icon notranslate theme--dark white--text"
                            style="font-size: 25px; height: 25px; width: 25px"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"
                              style="font-size: 25px; height: 25px; width: 25px">
                              <path :d="icons.mdiCloseCircleOutline"></path>
                            </svg></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-form>
          </v-card>
          <v-card class="mx-auto mt-10" outlined v-if="selected_magazine != null">
            <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
              <div data-v-6fcca708="" class="me-2 mb-2">
                <div data-v-6fcca708="" class="v-card__title pt-0 px-0">
                  {{ translate("Services") }}
                </div>
              </div>
              <button data-v-6fcca708="" v-on:click="
                services.push({
                  id: Math.random() * 10000,
                  amount: 1,
                  name: '',
                  text: 0,
                  notes: '',
                  quantity_price: 0,
                  discount: 0,
                  tax: 0,
                })
                " type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">
                <span class="v-btn__content">{{ translate("add") }}</span>
              </button>
            </div>
            <v-form v-model="fourth_form" ref="fourth_form" lazy-validation>
              <v-simple-table height="auto" max-height="500" fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase">
                        {{ translate("Service Name") }}
                      </th>
                      <th class="text-center text-uppercase">{{ translate("Text") }}</th>
                      <th class="text-center text-uppercase">{{ translate("Notes") }}</th>

                      <th class="text-center text-uppercase">{{ translate("Price") }}</th>
                      <th class="text-center text-uppercase">
                        {{ translate("Amount") }}
                      </th>

                      <th class="text-center text-uppercase">{{ translate("Total") }}</th>
                      <th class="text-center text-uppercase">{{ translate("") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="stock in services" :key="stock.id">
                      <td class="text-center col-2" style="padding: 10px">
                        <v-select :rules="[(v) => !!v || 'Item is required']" dense item-text="product_name"
                          v-model="stock['name']" class="shrink" outlined :items="computedProducts"></v-select>
                      </td>
                      <td class="text-center col-3" style="padding: 10px">
                        <v-textarea v-model="stock['text']" :label="translate('Text')" clear-icon="shrink" outlined
                          dense></v-textarea>
                      </td>

                      <td class="text-center col-2" style="padding: 10px">
                        <v-textarea v-model="stock['notes']" :label="translate('Notes')" clear-icon="shrink" outlined
                          dense></v-textarea>
                      </td>
                      <td class="text-center col-2" style="padding: 10px">
                        <v-text-field v-model="stock['quantity_price']" :label="translate('Service Price')"
                          clear-icon="shrink" :suffix="currency" outlined type="number" dense></v-text-field>
                      </td>
                      <td class="text-center col-1" style="padding: 10px">
                        <v-text-field :label="translate('Amount')" :key="key" v-model="stock['amount']"
                          :suffix="stock.measurement_name" outlined dense :rules="[
                            (v) => !!v || 'Item is required!',
                            (v) => v >= 0 || 'cant be zero',
                          ]" :min="1" type="number"></v-text-field>
                      </td>

                      <td>
                        <label v-if="stock['amount'] == 0 || stock['quantity_price'] == 0">0 {{ currency }}</label>
                        <label v-else>{{ calculateTotal(stock) }} {{ currency }}</label>
                      </td>

                      <td class="text-center">
                        <div v-on:click="
                          services = services.filter((item) => item !== stock)
                          " class="v-avatar elevation-1 rounded error"
                          style="height: 30px; min-width: 30px; width: 30px">
                          <span aria-hidden="true" class="v-icon notranslate theme--dark white--text"
                            style="font-size: 25px; height: 25px; width: 25px"><svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"
                              style="font-size: 25px; height: 25px; width: 25px">
                              <path :d="icons.mdiCloseCircleOutline"></path>
                            </svg></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-form>
          </v-card>
          <div data-v-6fcca708="" class="me-2 mb-2"></div>
          <div style="padding-left: 20px">
            <h4 class="text-decoration-none" style="font-weight: 500">
              {{ translate("Sub Total") }}: {{ getSubTotal }} {{ currency }}
            </h4>
            <h4 class="text-decoration-none" style="font-weight: 500; padding: 0">
              {{ translate("Total Discount") }}: {{ getDiscount }} {{ currency }}
            </h4>
            <h4 class="text-decoration-none" style="font-weight: 500; margin: 0">
              {{ translate("Total Tax") }}: {{ getTax }} {{ currency }}
            </h4>
            <h4 class="text-decoration-none" style="font-weight: 500">
              {{ translate("Delivery Fee") }}: {{ get_delivery_fee }} {{ currency }}
            </h4>
            <h4 class="text-decoration-none" style="font-weight: 500">
              {{ translate("Services") }}: {{ getServiceTotal }} {{ currency }}
            </h4>
            <h4 class="text-decoration-none" style="font-weight: 500">
              {{ translate("Profit") }}: {{ getProfit }} {{ currency }}
            </h4>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between flex-wrap" style="padding-left: 10px">
            <h3 style="font-weight: 600">
              {{ translate("Grand Total") }}: {{ getGrandTotal }} {{ currency }}
            </h3>
          </div>
          <v-btn color="success" style="margin: 10px" v-on:click="submit">
            {{ translate("Submit") }}
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="newClientDialog" width="60%">
      <new_clientVue @closeDialog="closeNewClientDialog" />
    </v-dialog>
    <v-dialog v-model="searchClientDialog" width="60%">
      <searchClientVue @closeDialog="closeSearchClientDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import axios from "axios";
import { mdiPlus, mdiCloseCircleOutline } from "@mdi/js";
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from "vue2-leaflet";
import { Icon } from "leaflet";
import * as moment from "moment";
import { intToHex } from "vuetify/lib/util/colorUtils";
import {
  editOrder,
  getApprovedDrivers,
  getApprovedUsers,
  getCurrency,
  getOrderInfo,
  getOrigins,
  getPaymentMethods,
  getProducts,
  getStocks,
  insertNeworder,
  searchForClientsById,
  getCities,
  getStates
} from "@/ApiManager";
import new_clientVue from "./new_client.vue";
import searchClientVue from "./searchClient.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    LMap,
    new_clientVue,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    searchClientVue,
    LIcon,
  },
  methods: {
    truncate(file, n) {
      var str = file.name.length > n ? file.name.slice(0, n - 1) + "..." : file.name;
      var extension = file.name.split(".").pop();
      str = str + extension;
      return str;
    },
    isFileImage(file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        return true;
      }
      return false;
    },
    productSkuChanged(product_id) {
      this.selected_stocks.push({
        id: Math.random() * 10000,
        product_id: product_id,
        image_url: "",
        amount: 1,
        quantity_price: 0,
        tax:
          this.selected_stocks.length == 0 ? 0 : this.selected_stocks.slice(-1).pop().tax,
        discount: 0,
        type: "",
      });
      var product_name = this.computedProducts.filter(
        (item) => item.product_id == product_id
      )[0].product_name;
      console.log(product_name);
      this.product_selected(
        this.selected_stocks[this.selected_stocks.length - 1],
        product_name
      );
      this.product_id_changed(this.selected_stocks[this.selected_stocks.length - 1]);
    },
    productSkuFilter(item, queryText, itemText) {
      var response = 0;
      if (item.product_sku.includes(queryText)) {
        response = 1;
      }
      if (itemText.includes(queryText)) {
        response = 1;
      }
      return response;
    },
    async searchForClient() {
      this.$store.state.loading = true;
      var result = await searchForClientsById(this.client_id);
      if (result.length != 0) {
        result = result[0];
        this.client_name = result["name"];
        this.phone_number = result["phone"];
        this.address = result["address"];
      }
      this.$store.state.loading = false;
    },
    getMixedProductAvailability(productId) { },
    closeNewClientDialog(data) {
      this.newClientDialog = false;
      if (data == null) {
        return;
      }
      console.log(data);
      this.client_id = data.client_id;
      this.old_client_id = data.client_id;
      this.client_name = data.client_name;
      this.phone_number = data.phone_number;
      this.address = data.address;
      this.markerLatLng = [data.lat, data.lng];
      this.idNumber = data.idNumber;
      this.idType = data.idType;
      this.area_id = data.area_id;
      this.city_id = data.city_id;
      this.state_id = data.state_id;
      this.zip_code = data.zip_code;
    },
    closeSearchClientDialog(data) {
      this.searchClientDialog = false;
      if (data == null) {
        return;
      }
      this.client_id = data.id;
      this.old_client_id = data.id;

      this.client_name = data.name;
      this.phone_number = data.phone;
      this.address = data.address;
      this.markerLatLng = [data.lat, data.lng];
      this.idNumber = data.idNumber;
      this.idType = data.idType;
      this.area_id = data.area_id;
      this.city_id = data.city_id;
      this.state_id = data.state_id;
      this.zip_code = data.zip_code;
    },
    async removeOrderImage(image) {
      this.files = this.files.filter(function (item) {
        return item.name != image.name;
      });
    },
    async openImagePrompt() {
      this.$refs.uploader.click();
    },
    async uploadImage(data) {
      var file = data.target.files[0];
      this.files.push(file);
      console.log(file);
    },

    mapclick(data) {
      this.markerLatLng = [data.latlng["lat"], data.latlng["lng"]];
    },

    turnStockToString(stock) {
      return `${stock.id}${stock.amount}${stock.product.id}${stock.quantity_price}${stock.tax}${stock.discount}`;
    },
    calculateTotal(stock) {
      var price = 0;
      //get total price
      price = stock.amount * stock.quantity_price;
      //calculate discount
      price = price - (price * stock.discount) / 100;

      //calculate tax
      price = price + (price * stock.tax) / 100;
      return price;
    },
    product_id_changed(stock) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (
          this.stocks[i].product_id == stock["product_id"] &&
          this.selected_magazine.name == this.stocks[i].magazine_name
        ) {
          stock["product_name"] = this.stocks[i].product_name;
          stock["product_id"] = this.stocks[i].product_id;
          stock["measurement_name"] = this.stocks[i].measurement_name;
          stock["magazine_id"] = this.stocks[i].magazine_id;
          stock["product_buy_price"] = this.stocks[i].buy_price;
          stock["quantity_price"] = this.stocks[i].sell_price;
          this.key += 1;
          return;
        }
      }
    },
    product_selected(stock, name) {
      for (var i = 0; i < this.computedStocks.length; i++) {
        if (
          (this.computedStocks[i].product_name == name &&
            this.selected_magazine.name == this.computedStocks[i].magazine_name) ||
          (this.computedStocks[i].product_name == name &&
            this.computedStocks[i].type == "mixed")
        ) {
          stock["product_id"] = this.computedStocks[i].product_id;
          stock["measurement_name"] = this.computedStocks[i].measurement_name;
          stock["image_url"] = this.computedStocks[i].image_url;
          stock["magazine_id"] = this.computedStocks[i].magazine_id;
          stock["product_buy_price"] = this.computedStocks[i].buy_price;
          stock["quantity_price"] = this.computedStocks[i].sell_price;
          stock["type"] = this.computedStocks[i].type;
          stock["products"] = this.computedStocks[i].products;

          this.key += 1;
          return;
        }
      }
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].magazine_name == magazine_name) {
          this.selected_stocks = [];
          this.selected_magazine = {
            name: this.stocks[i].magazine_name,
            id: this.stocks[i].magazine_id,
            lat: this.stocks[i].magazine_lat,
            lng: this.stocks[i].magazine_lng,
          };
          return;
        }
      }
    },
    submit: function (_) {
      this.$refs.first_form.validate();
      this.$refs.second_form.validate();
      this.$refs.third_form.validate();
      this.$refs.fourth_form.validate();
      if (
        this.$refs.first_form.validate() == false ||
        this.$refs.second_form.validate() == false ||
        this.$refs.third_form.validate() == false ||
        this.$refs.fourth_form.validate() == false
      ) {
        return;
      }
      this.origin = this.origin.id;
      this.payment_method = this.payment_method.id;
      this.$store.state.loading = true;
      var _services = [];
      for (var i = 0; i < this.services.length; i++) {
        var service = this.services[i];
        _services.push({
          name: service.name,
          quantity_price: service.quantity_price,
          amount: service.amount,
          text: service.text,
          notes: service.notes,
        });
      }
      var _designers = [];
      for (var i = 0; i < this.selectedDesigners.length; i++) {
        _designers.push(this.selectedDesigners[i].id);
      }
      var _productions = this.selectedProductions.id;
      var deadline = `${this.deadline.getFullYear()}/${(
        "0" +
        (this.deadline.getMonth() + 1)
      ).slice(-2)}/${("0" + this.deadline.getDate()).slice(
        -2
      )} ${this.deadline.getHours()}:${this.deadline.getMinutes()}:${this.deadline.getSeconds()}`;
      editOrder(
        this.client_name,
        this.salesPostToText(this.sales_post),
        this.selected_magazine.id,
        this.phone_number,
        this.email,
        this.address,
        this.markerLatLng[0],
        this.markerLatLng[1],
        this.getGrandTotal,
        deadline,
        this.driver_id,
        this.delivery_fee,
        this.selected_stocks,
        _services,
        this.getProfit,
        this.files,
        _designers,
        _productions,
        this.client_id,
        JSON.parse(localStorage.getItem("user")).id,
        this.origin,
        this.idNumber,
        this.idType,
        this.town,
        this.old_client_id,
        this.payment_method,
        this.$route.query.id,
        this.state_id,
        this.zip_code,
        this.currency_id,
        this.payment_amount,
        this.city_id,
        this.area_id,
      ).then((response) => {
        console.log(response);

        if (JSON.parse(localStorage.getItem("user")).role == "sales_agent") {
          this.$router.push({ name: "orders_sales" });
          return;
        }

        this.$router.push({ name: "orders" });
        this.$store.state.loading = true;
      });
    },
  },
  computed: {
    getDrivers() {
      var drivers = [];
      drivers.push({ value: "client_picks_up", text: "Shop Peshkopi" });
      drivers.push({ value: "delivery_company", text: "Addex" });
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "driver")
          drivers.push({ value: this.users[i].id, text: this.users[i].name });
      }

      return drivers;
    },
    getDesigners() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "designer") designers.push(this.users[i]);
      }
      return designers;
    },
    getProductions() {
      var productions = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "production") productions.push(this.users[i]);
      }
      return productions;
    },
    computedImages() {
      var images = [];
      for (var i = 0; i < this.files.length; i++) {
        images.push({
          url: URL.createObjectURL(this.files[i]),
          name: this.files[i].name,
          type: this.files[i].type,
        });
      }
      return images;
    },
    delivery_fee_visible() {
      if (this.textToSalesPost(this.sales_post) == "client_picks_up") {
        return false;
      } else {
        return true;
      }
    },
    computedStocks() {
      var key = this.computedStockKey;
      if (key == 0) {
        //this is just to update the computed property when the key changes
      }
      const stocks = JSON.parse(
        JSON.stringify(
          this.stocks.filter((item) => item.magazine_id == this.selected_magazine.id)
        )
      );
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var selectedStock = this.selected_stocks[i];
        for (var ii = 0; ii < stocks.length; ii++) {
          var stock = stocks[ii];
          if (stock.product_id == selectedStock.product_id) {
            stocks[ii].amount = stocks[ii].amount - selectedStock.amount;
          }
        }
      }
      //first get the list of products that can be crafted
      for (var i = 0; i < this.selected_stocks.length; i++) {
        selectedStock = this.selected_stocks[i];
        if (selectedStock.products != undefined) {
          selectedStock.products.forEach((element) => {
            for (var ii = 0; ii < stocks.length; ii++) {
              if (stocks[ii].product_id == element.id) {
                stocks[ii].amount =
                  stocks[ii].amount - element.amount * selectedStock.amount;
              }
            }
          });
        }
      }
      this.mixedProducts.forEach((mixedProduct) => {
        var add = true;
        var amounts = [];

        mixedProduct.products.forEach((product) => {
          for (var i = 0; i < stocks.length; i++) {
            if (stocks[i].product_id == product.id) {
              amounts.push(stocks[i].amount / product.amount);
            }
          }
        });
        const min = Math.min(...amounts);
        stocks.push({
          product_name: mixedProduct.name,
          image_url: mixedProduct.image_url,

          product_id: mixedProduct.id,
          amount: min,
          buy_price: mixedProduct.buy_price,
          sell_price: mixedProduct.sell_price,
          measurement_id: mixedProduct.measurement_id,
          measurement_name: mixedProduct.measurement_name,
          type: "mixed",
          products: mixedProduct.products,
        });
      });
      return stocks;
    },
    getDrivers() {
      var drivers = [];
      drivers.push({ value: "client_picks_up", text: "Shop Peshkopi" });
      drivers.push({ value: "delivery_company", text: "Addex" });
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "driver")
          drivers.push({ value: this.users[i].id, text: this.users[i].name });
      }

      return drivers;
    },
    computedProducts() {
      var stocks = this.stocks.filter(
        (item) => item.magazine_id == this.selected_magazine.id
      );
      //iterate through the mixed products, and add each one that is craftable
      this.mixedProducts.forEach((mixedProduct) => {
        var add = true;
        mixedProduct.products.forEach((product) => {
          var currentStock = this.stocks.find((o) => o.product_id == product.id);
          if (currentStock != undefined) {
            if (currentStock.amount < product.amount) {
              add = false;
            }
          } else {
            add = false;
          }
        });
        if (add == true) {
          //now we know we have enough product to craft this,so put it into the list
          stocks.push({ product_name: mixedProduct.name });
        }
      });
      for (var i = 0; i < stocks.length; i++) {
        if (stocks[i].product_sku != undefined) {
          stocks[i].skuName = `${stocks[i].product_name} - ${stocks[i].product_sku}`;
        }
      }
      return stocks;
    },
    dynamicSize() {
      return [this.iconSize * 0.5, this.iconSize * 0.5];
    },
    dynamicAnchor() {
      return [this.iconSize * 0.5, this.iconSize * 0.5];
    },
    getSubTotal() {
      var price = 0;
      for (var i = 0; i < this.selected_stocks.length; i++) {
        price += this.selected_stocks[i].quantity_price * this.selected_stocks[i].amount;
      }
      return price;
    },
    getServiceTotal() {
      var service = 0;
      for (var i = 0; i < this.services.length; i++) {
        service += this.services[i].quantity_price * this.services[i].amount;
      }
      return service;
    },
    getProfit() {
      //first calculate the buy price of each product INCLUDING taxes
      var profit = 0;
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var stock = this.selected_stocks[i];
        var product_buy_price = stock.product_buy_price + stock.product_buy_price * 0.2;
        var buy_cost_total = product_buy_price * stock.amount;
        var price = 0;
        //get total price
        price = stock.amount * stock.quantity_price;
        //calculate discount
        price = price - (price * stock.discount) / 100;
        profit += price - buy_cost_total;
      }

      return profit + this.getServiceTotal;
    },
    getDiscount() {
      var price = 0;
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var cost =
          this.selected_stocks[i].quantity_price * this.selected_stocks[i].amount;
        price += (cost * this.selected_stocks[i].discount) / 100;
      }
      return price;
    },
    getTax() {
      var price = 0;
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var cost =
          this.selected_stocks[i].quantity_price * this.selected_stocks[i].amount;
        cost -= (cost * this.selected_stocks[i].discount) / 100;
        price += (cost * this.selected_stocks[i].tax) / 100;
      }
      return price;
    },
    get_magazine() {
      return this.selected_magazine;
    },
    get_delivery_fee() {
      return this.delivery_fee;
    },
    getGrandTotal() {
      var price = 0;
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var stock = this.selected_stocks[i];
        //get total price
        var cost = stock.amount * stock.quantity_price;
        //calculate discount
        if (stock.discount != 0) {
          cost = cost - (cost * stock.discount) / 100;
        }
        //calculate tax
        if (stock.tax != 0) {
          cost = cost + (cost * stock.tax) / 100;
        }
        price += cost;
      }
      return parseInt(price) + parseInt(this.delivery_fee) + this.getServiceTotal;
    },
  },
  async created() {
    this.$store.state.loading = true;
    var orderId = this.$route.query.id;
    getCities().then((response) => {
      this.cities = response;
    });
    getStates().then((response) => {
      this.states = response;
    });
    this.stocks = await getStocks();
    this.payment_methods = await getPaymentMethods();
    this.currency = (await getCurrency())["currency"];
    this.users = await getApprovedUsers();
    this.mixedProducts = (await getProducts()).filter((item) => item.type == "mixed");
    this.origins = await getOrigins();
    getOrderInfo(orderId, true).then(async (response) => {
      console.log(response);
      //setup the user
      this.closeSearchClientDialog(response["client"]);
      //setup the selected magazine
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].magazine_id == response["order"]["magazine_id"]) {
          this.magazine_selected(this.stocks[i].magazine_name);
        }
      }
      //setup the payment method
      for (var i = 0; i < this.payment_methods.length; i++) {
        var payment_method = this.payment_methods[i];
        if (payment_method.id == response["order"]["payment_method"]) {
          this.payment_method = payment_method;
        }
      }
      //setup deadline
      this.deadline = new Date(response["order"]["deadline"]);
      //setup origin
      for (var i = 0; i < this.origins.length; i++) {
        var origin = this.origins[i];
        if (origin.id == response["order"]["origin_id"]) {
          this.origin = origin;
        }
      }
      this.currency_id = response['order']['currency_id']
      this.payment_amount = response['order']['payment_amount']
      //setup images
      for (var i = 0; i < response["images"].length; i++) {
        var image = response["images"][i];
        const _response = await fetch(image["image_url"]);
        const blob = await _response.blob();
        const file = new File([blob], image["image_url"], { type: image["filetype"] });
        this.files.push(file);
      }
      //setup stocks
      for (var i = 0; i < response["stocks"].length; i++) {
        var stock = response["stocks"][i];
        this.selected_stocks.push({
          id: Math.random() * 10000,
          product_id: stock["product_id"],
          image_url: stock["image_url"],
          amount: stock["amount"],
          quantity_price: stock["quantity_price"],
          tax:
            this.selected_stocks.length == 0
              ? 0
              : this.selected_stocks.slice(-1).pop().tax,
          discount: stock["discount"],
          type: "",
        });
        var product_name = this.computedProducts.filter(
          (item) => item.product_id == stock["product_id"]
        )[0].product_name;
        this.product_selected(
          this.selected_stocks[this.selected_stocks.length - 1],
          product_name
        );
        this.product_id_changed(this.selected_stocks[this.selected_stocks.length - 1]);
      }
      //setup services
      for (var i = 0; i < response["services"].length; i++) {
        var service = response["services"][i];
        this.services.push({
          id: Math.random() * 10000,
          amount: service["amount"],
          text: service["text"],
          notes: service["notes"],
          quantity_price: service["price"],
          name: service["name"],
        });
      }
      this.$store.state.loading = false;
    });
    try {
      var coordinates = await this.$getLocation();
    } catch (c) {
      var coordinates = { lat: 41.3092303, lng: 19.945797 };
    }
    this.center = [coordinates["lat"], coordinates["lng"]];
    this.markerLatLng = [coordinates["lat"], coordinates["lng"]];
    this.leaflet_key += 1;
  },
  data() {
    return {
      area_id: null,
      city_id: null,
      payment_amount: null,
      zip_code: null,
      currency_id: 1,
      states: null,
      state_id: null,
      cities: null,
      payment_method: null,
      old_client_id: null,
      origin: null,
      origins: null,
      computedStockKey: 0,
      first_form: false,
      second_form: false,
      third_form: false,
      fourth_form: false,
      client_id: null,
      newClientDialog: false,
      searchClientDialog: false,
      selectedProductions: [],
      selectedDesigners: [],
      files: [],
      sales_post: null,
      delivery_fee: 0,
      panel: 0,
      emptyRule: [(v) => !!v || "Can't be empty!"],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      center: [0, 0],
      markerLatLng: [0, 0],
      leaflet_key: 0,
      currency: "",
      deadline: new Date(),
      menu2: false,
      stocks: [],
      mixedProducts: [],
      services: [],
      selected_stocks: [],
      idNumber: null,
      idType: null,
      town: null,
      key: 0,
      selected_magazine: null,
      client_name: "",
      email: "",
      phone_number: "",
      address: "",
      magazines: [],
      users: [],
      payment_methods: [],
    };
  },
  setup() {
    const product_name = ref("");
    const leaflet_key = ref("");
    const inventory_address = ref("");
    const sell_price = ref("");

    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
      product_name,
      leaflet_key,
      inventory_address,
      sell_price,
    };
  },
};
</script>
<style scoped>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");

.bold {
  color: black;
  font-family: sans-serif;
  font-weight: 700;
}

.component-container {
  display: table;

  @media (pointer: none),
  (pointer: coarse) {
    width: calc(100% - (50%));
  }
}

.component-row {
  display: table-row;
}

.component-row div {
  position: relative;
  display: table-cell;
}

.component-row div:nth-child(2) {
  left: 20px;
}

.component-row div:nth-child(3) {
  left: 40px;
}
</style>
